*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Sen, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.z-0 {
  z-index: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

.h-2\/4 {
  height: 50%;
}

.h-80 {
  height: 20rem;
}

.h-20 {
  height: 5rem;
}

.h-screen {
  height: 100vh;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-48 {
  width: 12rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-9\/12 {
  width: 75%;
}

.w-1\/2 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-60 {
  width: 15rem;
}

.max-w-md {
  max-width: 28rem;
}

.appearance-none {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  align-content: center;
  justify-content: center;
}

.place-items-center {
  align-items: center;
  justify-items: center;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-green {
  --tw-border-opacity: 1;
  border-color: rgb(15 35 41 / var(--tw-border-opacity) );
}

.border-orange {
  --tw-border-opacity: 1;
  border-color: rgb(55 125 153 / var(--tw-border-opacity) );
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 241 / var(--tw-bg-opacity) );
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(15 35 41 / var(--tw-bg-opacity) );
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(230 230 230 / var(--tw-bg-opacity) );
}

.bg-level1 {
  --tw-bg-opacity: 1;
  background-color: rgb(62 149 172 / var(--tw-bg-opacity) );
}

.bg-level2 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 125 153 / var(--tw-bg-opacity) );
}

.bg-level3 {
  --tw-bg-opacity: 1;
  background-color: rgb(48 117 134 / var(--tw-bg-opacity) );
}

.bg-level4 {
  --tw-bg-opacity: 1;
  background-color: rgb(42 100 115 / var(--tw-bg-opacity) );
}

.bg-level5 {
  --tw-bg-opacity: 1;
  background-color: rgb(35 84 97 / var(--tw-bg-opacity) );
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(18 32 57 / var(--tw-bg-opacity) );
}

.object-fill {
  -o-object-fit: fill;
  object-fit: fill;
}

.p-10 {
  padding: 2.5rem;
}

.p-0 {
  padding: 0;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.text-green {
  --tw-text-opacity: 1;
  color: rgb(15 35 41 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(241 241 241 / var(--tw-text-opacity) );
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgb(55 125 153 / var(--tw-text-opacity) );
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

html {
  overflow-x: hidden;
}

p {
  color: #202425 !important;
}

.hyphens-auto {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -webkit-hyphens: auto !important;
}

.syn-details {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(0, 0, 0, .263), rgba(0, 0, 0, .263)), url("syn-details.e37a3226.jpg");
  background-position: 0 0, bottom;
  background-repeat: repeat, no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.border-top {
  border-top: 0 solid #e6e6e6;
}

.border-bottom {
  border-bottom: 3px solid #e6e6e6;
}

@media (min-width: 1024px) {
  .w-30percent {
    width: 30% !important;
  }

  .cards h3 {
    color: #f1f1f1;
    font-size: 3rem;
    font-weight: 700;
  }

  .card_1 {
    width: 100%;
    height: 100%;
    filter: grayscale();
    -webkit-filter: grayscale();
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    background: url("card_1.31f43286.jpeg") center / cover no-repeat;
    border-radius: 0;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    display: flex;
    border: 5px solid #000 !important;
  }

  .card_1:hover {
    filter: grayscale(1%);
    -webkit-filter: grayscale(1%);
    -moz-filter: grayscale(1%);
    -ms-filter: grayscale(1%);
    -o-filter: grayscale(1%);
    border-bottom-left-radius: 10em;
    transition: all .25s;
  }

  .card_2 {
    width: 100%;
    height: 100%;
    filter: grayscale();
    -webkit-filter: grayscale();
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    background: url("caleb.dbad91b9.jpg") center / cover no-repeat;
    border-radius: 0;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    display: flex;
    border: 5px solid #000 !important;
  }

  .card_2:hover {
    filter: grayscale(1%);
    -webkit-filter: grayscale(1%);
    -moz-filter: grayscale(1%);
    -ms-filter: grayscale(1%);
    -o-filter: grayscale(1%);
    border-bottom-left-radius: 10em;
    transition: all .25s;
  }

  .card_3 {
    width: 100%;
    height: 100%;
    filter: grayscale();
    -webkit-filter: grayscale();
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    background: url("card_1.31f43286.jpeg") center / cover no-repeat;
    border-radius: 0;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    display: flex;
    border: 5px solid #000 !important;
  }

  .card_3:hover {
    filter: grayscale(1%);
    -webkit-filter: grayscale(1%);
    -moz-filter: grayscale(1%);
    -ms-filter: grayscale(1%);
    -o-filter: grayscale(1%);
    border-bottom-left-radius: 10em;
    transition: all .25s;
  }

  .card_4 {
    width: 100%;
    height: 100%;
    filter: grayscale();
    -webkit-filter: grayscale();
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    background: url("anastasia_burnett.551fb4ed.jpg") center / cover no-repeat;
    border-radius: 0;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    display: flex;
    border: 5px solid #000 !important;
  }

  .card_4:hover {
    filter: grayscale(1%);
    -webkit-filter: grayscale(1%);
    -moz-filter: grayscale(1%);
    -ms-filter: grayscale(1%);
    -o-filter: grayscale(1%);
    border-bottom-left-radius: 10em;
    transition: all .25s;
  }

  .card_5 {
    width: 100%;
    height: 100%;
    filter: grayscale();
    -webkit-filter: grayscale();
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    background: url("Will_Depp.73904dfa.jpg") center / cover no-repeat;
    border-radius: 0;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    display: flex;
    border: 5px solid #000 !important;
  }

  .card_5:hover {
    filter: grayscale(1%);
    -webkit-filter: grayscale(1%);
    -moz-filter: grayscale(1%);
    -ms-filter: grayscale(1%);
    -o-filter: grayscale(1%);
    border-bottom-left-radius: 10em;
    transition: all .25s;
  }

  .card_6 {
    width: 100%;
    height: 100%;
    filter: grayscale();
    -webkit-filter: grayscale();
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    background: url("Kate_Orsini.a6d49e47.jpg") center / cover no-repeat;
    border-radius: 0;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    display: flex;
    border: 5px solid #000 !important;
  }

  .card_6:hover {
    filter: grayscale(1%);
    -webkit-filter: grayscale(1%);
    -moz-filter: grayscale(1%);
    -ms-filter: grayscale(1%);
    -o-filter: grayscale(1%);
    border-bottom-left-radius: 10em;
    transition: all .25s;
  }

  .card_7 {
    width: 100%;
    height: 100%;
    filter: grayscale();
    -webkit-filter: grayscale();
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    background: url("kevin.176c8341.jpg") center / cover no-repeat;
    border-radius: 0;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    display: flex;
    border: 5px solid #000 !important;
  }

  .card_7:hover {
    filter: grayscale(1%);
    -webkit-filter: grayscale(1%);
    -moz-filter: grayscale(1%);
    -ms-filter: grayscale(1%);
    -o-filter: grayscale(1%);
    border-bottom-left-radius: 10em;
    transition: all .25s;
  }

  .card_8 {
    width: 100%;
    height: 100%;
    filter: grayscale();
    -webkit-filter: grayscale();
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    background: url("james.99595b56.jpg") center / cover no-repeat;
    border-radius: 0;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: all .25s;
    display: flex;
    border: 5px solid #000 !important;
  }

  .card_8:hover {
    filter: grayscale(1%);
    -webkit-filter: grayscale(1%);
    -moz-filter: grayscale(1%);
    -ms-filter: grayscale(1%);
    -o-filter: grayscale(1%);
    border-bottom-left-radius: 10em;
    transition: all .25s;
  }

  .tagline {
    width: 50%;
    color: #f1f1f1;
    text-transform: uppercase;
    letter-spacing: 1px;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 300;
    display: inline-flex !important;
  }

  .social {
    flex-flow: row;
    justify-content: right;
    align-items: center;
    display: inline-flex;
    width: 10% !important;
  }

  .desktop-hide {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .mobile-hide {
    display: none !important;
  }

  .logo {
    min-height: 50px !important;
    width: 15% !important;
    margin-left: 30px !important;
  }

  .logo_footer {
    min-height: 180px !important;
  }

  .landon-img, .caleb-img, .ty-img, .anna-img, .will-img {
    height: 350px !important;
  }

  .intro_section_header > p {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }

  .arrow {
    margin-bottom: 15vh !important;
  }

  .support_col {
    padding-bottom: 5vh;
    height: auto !important;
    width: 100% !important;
  }

  .terms {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .site_credit, .copyright {
    text-align: left !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .levels-row {
    height: auto !important;
  }

  .cast {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}

#scrollbar {
  outline: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.menu-icon {
  height: auto;
  width: 30px;
  z-index: 9999;
  cursor: pointer;
  position: relative;
}

.menu-icon:hover > .menu-icon__line-left, .menu-icon:hover > .menu-icon__line-right {
  width: 30px;
  transition: all .5s ease-in-out;
}

body.nav-active .menu-icon:hover > .menu-icon__line-left, body.nav-active .menu-icon:hover > .menu-icon__line-right {
  width: 15px;
  transition: all .5s ease-in-out;
}

.menu-icon__line {
  height: 2px;
  width: 30px;
  background-color: #f1f1f1;
  margin-bottom: 4px;
  transition: transform .2s, background-color .5s;
  display: block;
}

.menu-icon__line-left {
  width: 15px;
  transition: all .5s ease-in-out;
}

.menu-icon__line-right {
  width: 15px;
  float: right;
  transition: all .5s ease-in-out;
}

.nav-desktop {
  z-index: auto;
  width: 55%;
  flex-flow: row;
  align-content: center;
  align-items: center;
  transition: all 1s;
  display: flex;
  position: relative;
  justify-content: flex-end !important;
}

.nav-desktop > div {
  width: 100%;
  flex-flow: row;
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between !important;
}

.nav-desktop > div > ul {
  width: 90%;
  color: #f1f1f1;
  text-transform: uppercase;
  flex-flow: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-family: Sen, sans-serif;
  font-weight: 400;
  display: inline-flex;
}

.nav-desktop > div > ul > li {
  transition: all .25s ease-in-out;
}

.nav-desktop > div > ul > li:hover {
  cursor: pointer;
  text-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
  transition: all .25s ease-in-out;
}

.nav-desktop > button {
  color: #122039;
  height: 50px;
  width: 145px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #f1f1f1;
  border-radius: 5em;
  font-weight: 700;
  transition: all .25s ease-in-out;
}

.nav-desktop > button:hover {
  color: #f1f1f1;
  background: #b30000;
  border-radius: 5em;
  transition: all .25s ease-in-out;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
}

.scroll-up + header > div > .nav-desktop > button:hover {
  color: #122039;
  background: #f1f1f1;
  border-radius: 5em;
  transition: all .25s ease-in-out;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
}

.nav {
  z-index: auto;
  transition: all 1s;
  position: absolute;
}

.nav:before, .nav:after {
  content: "";
  width: 100vw;
  height: 100vh;
  z-index: 50;
  background: #122039;
  transition: transform .8s cubic-bezier(.77, 0, .175, 1);
  position: fixed;
  transform: translateX(0%)translateY(-100%);
}

.nav:after {
  background: #122039;
  transition-delay: .1s;
}

.nav:before {
  transition-delay: .1s;
}

.nav__content {
  width: 100%;
  text-align: center;
  cursor: pointer;
  z-index: -999999;
  flex-flow: column;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc(2vw + 10px);
  font-weight: 200;
  transition-property: z-index;
  transition-delay: .4s;
  display: flex;
  position: fixed;
  top: 50%;
  transform: translate(0%, -50%);
}

.nav__list {
  width: 100%;
  height: 60vh;
  color: #fff;
  flex-flow: column;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 5vh;
  padding-left: 7.5vw;
  font-size: 2.75rem;
  display: flex;
}

.nav__list-item {
  opacity: 0;
  margin-right: 25px;
  transition-delay: .8s;
  display: inline-block;
  position: relative;
  transform: translate(0%, 100%);
}

.nav__list-item:nth-child(1) {
  transition: all 1s;
}

.nav__list-item:nth-child(2) {
  transition: all .9s;
}

.nav__list-item:nth-child(3) {
  transition: all .8s;
}

.nav__list-item:nth-child(4) {
  transition: all .7s;
}

.nav__list-item:nth-child(5) {
  transition: all .6s;
}

.nav__list-item:before {
  content: "";
  width: 20px;
  height: 1px;
  z-index: -1;
  background: #fff;
  transition: all .3s;
  position: absolute;
  top: 100%;
  transform: translate(0%);
}

.nav__list-item:hover:before {
  width: 100%;
}

body.nav-active .menu-icon__line {
  background-color: #fff;
  transform: translateX(0)rotate(-45deg);
}

body.nav-active .menu-icon__line-left {
  transform: translateX(1.5px)rotate(45deg);
}

body.nav-active .menu-icon__line-right {
  transform: translateX(-1.5px)rotate(45deg);
}

body.nav-active .nav__content {
  z-index: 500;
  transition-property: z-index;
  transition-delay: .4s;
}

body.nav-active .nav {
  visibility: visible;
  transition: all 1s;
}

body.nav-active .nav:before, body.nav-active .nav:after {
  transform: translateX(0%)translateY(0%);
}

body.nav-active .nav:after {
  background: #122039;
  transition-delay: .1s;
}

body.nav-active .nav:before {
  background: #122039;
  transition-delay: 0s;
}

body.nav-active .nav__list-item {
  opacity: 1;
  transition: opacity .3s, transform .3s, color .3s;
  transform: translateX(0%);
}

body.nav-active .nav__list-item:nth-child(0) {
  transition-delay: .5s;
}

body.nav-active .nav__list-item:nth-child(1) {
  transition-delay: .6s;
}

body.nav-active .nav__list-item:nth-child(2) {
  transition-delay: .7s;
}

body.nav-active .nav__list-item:nth-child(3) {
  transition-delay: .8s;
}

body.nav-active .nav__list-item:nth-child(4) {
  transition-delay: .9s;
}

body.nav-active .nav__list-item:nth-child(5) {
  transition-delay: 1s;
}

body.nav-active .nav__list-item:nth-child(6) {
  transition-delay: 1.1s;
}

header {
  z-index: 9999;
  position: fixed;
}

.header-inner {
  height: 90px;
  padding: 10vh 6vw;
  transition: all .25s;
  position: fixed;
  top: 0;
}

.scroll-down .header-inner {
  height: 90px;
  transition: all .25s;
  transform: translate3d(0, -100%, 0);
}

.scroll-up .header-inner {
  height: 90px;
  padding: 5vh 6vw;
  transition: all .25s;
  transform: none;
}

.scroll-up:not(.menu-open) .header-inner {
  box-shadow: none;
  height: 90px;
  background: #122039;
}

.scroll-up:not(.menu-open) .header-inner > .logo {
  height: 100%;
  min-height: 80px;
  visibility: visible;
  background: url("the-exhibit-logo.acea8727.png") center / cover no-repeat;
}

.logo {
  height: 100%;
  min-height: 80px;
  visibility: hidden;
  width: 8%;
  background: url("the-exhibit-logo.acea8727.png") center / cover no-repeat;
}

.logo_footer {
  height: 100%;
  min-height: 175px;
  background: url("the-exhibit-logo.acea8727.png") center / cover no-repeat;
}

.brand {
  width: auto;
}

.menu_label {
  width: 45%;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.menu_section {
  height: 45px;
  width: 250px;
  flex-flow: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.donate_cta {
  color: #122039;
  height: 50px;
  width: 145px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #f1f1f1;
  border-radius: 5em;
  font-size: 1.5rem;
  font-weight: 700;
  transition: all .25s ease-in-out;
  position: relative;
}

.help_cta {
  color: #fff;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #c65e0c;
  border-radius: 5em;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  transition: all .25s ease-in-out;
  display: flex;
  position: relative;
}

.help_cta:hover {
  background: #11373e;
  transition: all .25s ease-in-out;
}

.alt_cta {
  color: #0f2329;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #f1f1f1;
  border: 1px solid #0f2329;
  border-radius: 5em;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  transition: all .25s ease-in-out;
  display: flex;
  position: relative;
}

.alt_cta:hover {
  color: #f1f1f1;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #0f2329;
  border: 1px solid #fff;
  border-radius: 5em;
  flex-wrap: nowrap;
  font-weight: 700;
  transition: all .25s ease-in-out;
  position: relative;
}

footer {
  height: auto;
  border-top: 1px solid rgba(255, 255, 255, .1);
}

footer .logo {
  width: 15%;
}

.footer_inner {
  height: auto;
  width: 100vw;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 5vh 5vw;
  display: flex;
}

.footer_bottom {
  width: 100vw;
  border-top: 1px solid rgba(255, 255, 255, .1);
  flex-wrap: nowrap;
  padding: 0 5vw 2vh;
  display: flex;
}

.site_credit {
  color: rgba(255, 255, 255, .39);
  width: 100%;
  flex-flow: row;
  align-items: center;
  padding-top: 20px;
  font-size: .75rem;
  font-weight: 300;
  display: inline-flex;
}

.terms {
  color: rgba(255, 255, 255, .39);
  width: 100%;
  flex-flow: row;
  align-items: center;
  padding-top: 20px;
  font-size: .75rem;
  font-weight: 300;
  display: inline-flex;
  white-space: nowrap !important;
}

.copyright {
  color: rgba(255, 255, 255, .39);
  width: 100%;
  flex-flow: row;
  align-items: center;
  padding-top: 20px;
  font-size: .75rem;
  font-weight: 300;
  display: inline-flex;
}

.social {
  width: 40%;
  flex-flow: row;
  align-items: center;
  display: inline-flex;
}

.tagline {
  display: none;
}

.fa-brands {
  color: #f1f1f1;
  transition: all .5s ease-in-out;
  transform: scale(1.5);
}

.fa-brands:hover {
  transition: all .5s ease-in-out;
  transform: scale(1.75);
}

.preview-stills {
  background: #000;
}

.still {
  width: 100vw;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: inline-flex;
}

.carousel {
  width: 100vw;
  height: 100vh;
  background: #f1f1f1;
  position: relative;
  overflow: hidden;
}

.carousel .img-copyright {
  padding-bottom: 2vh;
  padding-right: 2vw;
  position: absolute;
  bottom: 0;
  right: 0;
}

.img-copyright p {
  text-align: right;
  font-size: .5rem;
  color: rgba(255, 255, 255, .6) !important;
}

@media (max-width: 730px) {
  .carousel {
    height: 50vh;
  }

  .carousel .slides {
    height: 100%;
  }

  .carousel .slides img {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.carousel .slides {
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.carousel .slides .slide {
  min-width: 100%;
  min-height: 250px;
  height: auto;
}

.carousel .slides img {
  -o-object-fit: cover;
  object-fit: cover;
}

.terms .hover-underline-animation {
  color: rgba(255, 255, 255, .39);
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.terms .hover-underline-animation:hover {
  color: rgba(255, 255, 255, .39);
  display: inline-block;
  position: relative;
}

.terms .hover-underline-animation:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 100% 100%;
  background-color: rgba(255, 255, 255, .39);
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.terms .hover-underline-animation:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

.site_credit .hover-underline-animation {
  color: rgba(255, 255, 255, .39);
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.site_credit .hover-underline-animation:hover {
  color: rgba(255, 255, 255, .39);
  display: inline-block;
  position: relative;
}

.site_credit .hover-underline-animation:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 100% 100%;
  background-color: rgba(255, 255, 255, .39);
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.site_credit .hover-underline-animation:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

.hover-underline-animation-white {
  color: #f1f1f1;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.hover-underline-animation-white:hover {
  color: #f1f1f1;
  display: inline-block;
  position: relative;
}

.hover-underline-animation-white:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 100% 100%;
  background-color: #f1f1f1;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.hover-underline-animation-white:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

.hover-underline-animation-lb {
  color: #202425;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.hover-underline-animation-lb:hover {
  color: #202425;
  display: inline-block;
  position: relative;
}

.hover-underline-animation-lb:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 100% 100%;
  background-color: #202425;
  transition: transform .25s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.hover-underline-animation-lb:hover:after {
  transform-origin: 0 100%;
  transform: scaleX(1);
}

h1 {
  color: #f1f1f1;
  white-space: nowrap;
  font-family: Sen, sans-serif;
  font-weight: 700;
  line-height: 1.05;
}

section {
  height: auto;
}

h2 {
  color: #122039;
  font-size: 5rem;
  font-weight: 700;
}

.h2-cutout {
  -webkit-text-fill-color: transparent;
  background: url("blue.1ab612cd.svg") center no-repeat;
  -webkit-background-clip: text;
  font-size: 5rem;
  font-weight: 700;
}

.subscription_section > div {
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 1536px) {
  .subscription_section {
    padding: 5vh 25vw;
  }

  .subscription_section p {
    width: 100%;
    flex-flow: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    font-weight: 100;
    display: inline-flex;
  }
}

@media (min-width: 1280px) and (max-width: 1536px) {
  .subscription_section {
    padding: 5vh 20vw;
  }

  .subscription_section p {
    width: 100%;
    flex-flow: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    font-weight: 100;
    display: inline-flex;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .subscription_section {
    padding: 5vh 10vw;
  }

  .subscription_section p {
    width: 100%;
    flex-flow: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    font-weight: 100;
    display: inline-flex;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .subscription_section {
    padding: 5vh 5vw;
  }

  .subscription_section p {
    width: 100%;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    display: inline-flex;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .subscription_section {
    height: 20vh;
    flex-flow: column;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    padding: 2vh 5vw;
    display: flex;
  }

  .subscription_section p {
    width: 100%;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    display: inline-flex;
  }
}

@media (min-width: 0) and (max-width: 640px) {
  .subscription_section {
    height: 20vh;
    flex-flow: column;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    padding: 2vh 5vw;
    display: flex;
  }

  .subscription_section p {
    width: 100%;
    flex-flow: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    display: inline-flex;
  }
}

.why_section {
  height: auto;
  padding-left: 5vw;
  padding-right: 5vw;
}

.why-header {
  flex-flow: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 15vh;
  display: inline-flex;
}

.why-header > h1 {
  padding-bottom: 5vh;
  font-weight: 700;
  line-height: 1.05;
}

.why-header > p {
  text-align: center;
  text-align-last: center;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  width: 90%;
  font-weight: 700;
  line-height: 1.75;
}

.why-content_txt_col > hr {
  height: 2px;
  width: 24vw;
  background: #4778bf;
  border: none;
  margin-bottom: 19px;
  position: relative;
  top: 0;
}

.why-content_txt_col > p {
  text-align: justify;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align-last: left;
  padding: 2.5vh 5vw 5vh 0;
  font-size: 1.25vw;
  font-weight: 100;
}

.team-img {
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.team-img > img {
  width: 70%;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
}

.content {
  width: 450px;
  height: 40px;
  border-radius: 5em;
  overflow: hidden;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
}

.subscription {
  width: 100%;
  height: 100%;
  position: relative;
}

.subscription .add-email {
  width: 100%;
  height: 100%;
  background: rgba(241, 241, 241, 0);
  border: .1vh solid rgba(255, 255, 255, .22);
  border-radius: 5em;
  outline: none;
  padding: 0 20px;
  transition: all .35s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.subscription .add-email:focus {
  background: #f1f1f1;
  transition: all .35s ease-in-out;
}

.subscription .add-email:focus + .submit-email {
  color: #f1f1f1;
  background: #122039;
  border: .1vh solid #f1f1f1;
  transition: all .35s ease-in-out;
}

.subscription .add-email:focus + .submit-email:hover {
  color: #f1f1f1;
  background: #4778bf;
  border: .1vh solid #f1f1f1;
  transition: all .35s ease-in-out;
}

.subscription .add-email + .subscribe {
  color: #122039;
  transition: all .35s ease-in-out;
}

.subscription .add-email:focus + .subscribe {
  color: #f1f1f1;
  transition: all .35s ease-in-out;
}

.subscription .submit-email {
  height: 40px;
  width: 205px;
  cursor: pointer;
  color: #122039;
  background: #f1f1f1;
  border: .1vh solid #122039;
  border-radius: 5em;
  outline: none;
  padding: 0 20px;
  transition: width .35s ease-in-out, background .35s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
}

.subscription.done .submit-email {
  width: 100%;
  background: url("blue.1ab612cd.svg") center no-repeat;
  border-radius: 5em;
}

.subscription .submit-email .before-submit, .subscription .submit-email .after-submit {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 40px;
  transition: visibility .35s ease-in-out, opacity .35s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.subscription.done .submit-email .before-submit, .subscription:not(.done) .submit-email .after-submit {
  visibility: hidden;
  opacity: 0;
}

.subscription .submit-email .after-submit {
  background: url("blue.1ab612cd.svg") center no-repeat;
  border: none;
  border-radius: 5em;
  transition-delay: .35s;
}

.subscription:not(.done) .submit-email .before-submit {
  visibility: visible;
  opacity: 1;
}

.subscription.done .submit-email .after-submit {
  visibility: visible;
  opacity: 1;
  color: #f1f1f1;
}

canvas {
  z-index: -1;
  overflow: scroll;
}

#canvas {
  width: 100%;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.intro_section {
  height: auto;
  min-height: 100vh;
}

.intro_section_header > p {
  text-align-last: left;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-left: 5vw;
  padding-right: 5vw;
}

.row {
  flex-flow: row;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 5vw;
  display: flex;
}

.col-auto {
  width: 30%;
  flex-flow: column;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.intro_section > .row > .col-auto > p {
  text-align: justify;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align-last: left;
  font-weight: 100;
}

.para-numbering {
  width: 100%;
  flex-flow: column;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 0;
  font-size: 1.5vw;
  display: flex;
}

hr {
  height: 2px;
  background: #f1f1f1;
  border: none;
  margin-bottom: 19px;
  position: relative;
  top: 8px;
}

.hr-blue {
  height: 2px;
  background: #4778bf;
  border: none;
  margin-bottom: 19px;
  position: relative;
  top: 8px;
}

.cards {
  height: auto;
  flex-flow: wrap;
  align-content: center;
  justify-content: space-evenly;
  display: flex;
}

.cards > a {
  height: 500px;
}

.cards h3 {
  color: #f1f1f1;
  font-size: 2.75rem;
  font-weight: 700;
}

.card_1 {
  width: 100%;
  height: 100%;
  filter: grayscale();
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  background: url("landon.7a8de82a.jpg") center / cover no-repeat;
  border: 2px solid #000;
  border-radius: 0;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.card_1:hover {
  filter: grayscale(1%);
  -webkit-filter: grayscale(1%);
  -moz-filter: grayscale(1%);
  -ms-filter: grayscale(1%);
  -o-filter: grayscale(1%);
  border-bottom-left-radius: 10em;
  transition: all .25s;
}

.card_2 {
  width: 100%;
  height: 100%;
  filter: grayscale();
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  background: url("caleb.dbad91b9.jpg") center / cover no-repeat;
  border: 2px solid #000;
  border-radius: 0;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.card_2:hover {
  filter: grayscale(1%);
  -webkit-filter: grayscale(1%);
  -moz-filter: grayscale(1%);
  -ms-filter: grayscale(1%);
  -o-filter: grayscale(1%);
  border-bottom-left-radius: 10em;
  transition: all .25s;
}

.card_3 {
  width: 100%;
  height: 100%;
  filter: grayscale();
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  background: url("Ty.8d20f03a.jpg") center / cover no-repeat;
  border: 2px solid #000;
  border-radius: 0;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.card_3:hover {
  filter: grayscale(1%);
  -webkit-filter: grayscale(1%);
  -moz-filter: grayscale(1%);
  -ms-filter: grayscale(1%);
  -o-filter: grayscale(1%);
  border-bottom-left-radius: 10em;
  transition: all .25s;
}

.card_4 {
  width: 100%;
  height: 100%;
  filter: grayscale();
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  background: url("anastasia_burnett.551fb4ed.jpg") center / cover no-repeat;
  border: 2px solid #000;
  border-radius: 0;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.card_4:hover {
  filter: grayscale(1%);
  -webkit-filter: grayscale(1%);
  -moz-filter: grayscale(1%);
  -ms-filter: grayscale(1%);
  -o-filter: grayscale(1%);
  border-bottom-left-radius: 10em;
  transition: all .25s;
}

.card_5 {
  width: 100%;
  height: 100%;
  filter: grayscale();
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  background: url("Will_Depp.73904dfa.jpg") center / cover no-repeat;
  border: 2px solid #000;
  border-radius: 0;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.card_5:hover {
  filter: grayscale(1%);
  -webkit-filter: grayscale(1%);
  -moz-filter: grayscale(1%);
  -ms-filter: grayscale(1%);
  -o-filter: grayscale(1%);
  border-bottom-left-radius: 10em;
  transition: all .25s;
}

.card_6 {
  width: 100%;
  height: 100%;
  filter: grayscale();
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  background: url("Kate_Orsini.a6d49e47.jpg") top / cover no-repeat;
  border: 2px solid #000;
  border-radius: 0;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.card_6:hover {
  filter: grayscale(1%);
  -webkit-filter: grayscale(1%);
  -moz-filter: grayscale(1%);
  -ms-filter: grayscale(1%);
  -o-filter: grayscale(1%);
  border-bottom-left-radius: 10em;
  transition: all .25s;
}

.card_7 {
  width: 100%;
  height: 100%;
  filter: grayscale();
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  background: url("kevin.176c8341.jpg") top / cover no-repeat;
  border: 2px solid #000;
  border-radius: 0;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.card_7:hover {
  filter: grayscale(1%);
  -webkit-filter: grayscale(1%);
  -moz-filter: grayscale(1%);
  -ms-filter: grayscale(1%);
  -o-filter: grayscale(1%);
  border-bottom-left-radius: 10em;
  transition: all .25s;
}

.card_8 {
  width: 100%;
  height: 100%;
  filter: grayscale();
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  background: url("james.99595b56.jpg") center / cover no-repeat;
  border: 2px solid #000;
  border-radius: 0;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.card_8:hover {
  filter: grayscale(1%);
  -webkit-filter: grayscale(1%);
  -moz-filter: grayscale(1%);
  -ms-filter: grayscale(1%);
  -o-filter: grayscale(1%);
  border-bottom-left-radius: 10em;
  transition: all .25s;
}

.home_banner {
  height: auto;
  min-height: 75vh;
  background: #000;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home_banner > div {
  padding: 0 25vw;
  display: inline-flex;
}

.home_banner > div > img {
  width: 50%;
  -webkit-clip-path: url("#layer_1");
  -webkit-clip-path: url("#layer_1");
  clip-path: url("#layer_1");
  background: url("banner_1.4c113c59.jpg");
}

.landing_home {
  height: auto;
  min-height: 100vh;
  width: 100vw;
  background: url("TheExhibit_16x9.b2c6dd6d.gif") center / cover no-repeat;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  align-items: flex-end;
  padding: 0 6vw;
  display: flex;
}

@media (max-width: 730px) {
  .landing_home {
    min-height: -webkit-fill-available;
    height: 100vh;
    width: 100vw;
    background: #f1f1f1 url("TheExhibit_9x16.5ab9ddee.gif") top / contain no-repeat;
  }
}

#background-video {
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.landing_cta {
  width: 50%;
  flex-flow: row;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  padding-top: 10vh;
  display: flex;
}

.landing_cta > button {
  width: 200px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 5em;
}

.countries {
  opacity: 1;
}

.country-hover {
  transition: all .25s ease-in-out;
}

.country-hover:hover {
  cursor: pointer;
  transition: all .25s ease-in-out;
  color: #4778bf !important;
}

.read-more-wrap {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.read-more {
  color: #4778bf;
  text-transform: uppercase;
}

.read-more:hover {
  cursor: pointer;
}

.efforts {
  height: 50px;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  background: #4778bf;
  margin-top: 5vh;
  transition: all .25s ease-in-out;
}

.efforts:hover {
  border-bottom-left-radius: 25px;
  transition: all .25s ease-in-out;
}

.read-more-state {
  display: none;
}

.read-more-target {
  transition: all .25s ease-in-out;
  display: none;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  display: initial;
  transition: all .25s ease-in-out;
  display: flex !important;
}

.read-more-state:checked ~ .read-more-wrap .read-more-remove {
  opacity: 0;
  font-size: inherit;
  max-height: 999em;
  transition: all .25s ease-in-out;
}

.read-more-state + div > div .read-more-trigger:before {
  content: "▼";
  color: #377d99;
  font-size: 1rem;
  text-decoration: overline;
}

.read-more-state:checked + div > div .read-more-trigger:before {
  content: "▲";
  color: #377d99;
  font-size: 1rem;
  text-decoration: underline;
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
}

.swup-transition-fade {
  opacity: 1;
  transition: all .5s;
}

html.is-animating .swup-transition-fade {
  opacity: 0;
  transition: all .5s;
}

.landon-img {
  background: url("landon.7a8de82a.jpg") center / cover no-repeat;
}

.caleb-img {
  background: url("caleb.dbad91b9.jpg") center / cover no-repeat;
}

.anna-img {
  background: url("anastasia_burnett.551fb4ed.jpg") center / cover no-repeat;
}

.will-img {
  background: url("Will_Depp.73904dfa.jpg") center / cover no-repeat;
}

.ty-img {
  background: url("Ty.8d20f03a.jpg") center / cover no-repeat;
}

.kevin-img {
  min-height: 445px;
  background: url("kevin.176c8341.jpg") top / cover no-repeat;
}

.kate-img {
  min-height: 445px;
  background: url("Kate_Orsini.a6d49e47.jpg") top / cover no-repeat;
}

.james-img {
  min-height: 445px;
  background: url("james.99595b56.jpg") center / cover no-repeat;
}

.cast {
  padding-left: 5vw;
  padding-right: 5vw;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.arrow {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=");
  background-size: contain;
  margin-bottom: 5vh;
  position: relative;
  bottom: 0;
  left: 0%;
}

.bounce {
  animation: bounce 2s infinite;
}

.support {
  padding-left: 5vw;
  padding-right: 5vw;
}

.support_col {
  height: 645px;
  width: 19%;
}

ul {
  list-style: disc !important;
}

.level1 {
  border: 1px solid #3e95ac;
}

.level2 {
  border: 1px solid #377d99;
}

.level3 {
  border: 1px solid #307586;
}

.level4 {
  border: 1px solid #2a6473;
}

.level5 {
  border: 1px solid #235461;
}

.level1_btn {
  color: #fff;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #3e95ac;
  border-radius: 5em;
  flex-wrap: nowrap;
  font-weight: 700;
  transition: all .25s ease-in-out;
  position: relative;
}

.level1_btn:hover {
  background: #0f2329;
  transition: all .25s ease-in-out;
}

.level2_btn {
  color: #fff;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #377d99;
  border-radius: 5em;
  flex-wrap: nowrap;
  font-weight: 700;
  transition: all .25s ease-in-out;
  position: relative;
}

.level2_btn:hover {
  background: #0f2329;
  transition: all .25s ease-in-out;
}

.level3_btn {
  color: #fff;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #307586;
  border-radius: 5em;
  flex-wrap: nowrap;
  font-weight: 700;
  transition: all .25s ease-in-out;
  position: relative;
}

.level3_btn:hover {
  background: #0f2329;
  transition: all .25s ease-in-out;
}

.level4_btn {
  color: #fff;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #2a6473;
  border-radius: 5em;
  flex-wrap: nowrap;
  font-weight: 700;
  transition: all .25s ease-in-out;
  position: relative;
}

.level4_btn:hover {
  background: #0f2329;
  transition: all .25s ease-in-out;
}

.level5_btn {
  color: #fff;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #235461;
  border-radius: 5em;
  flex-wrap: nowrap;
  font-weight: 700;
  transition: all .25s ease-in-out;
  position: relative;
}

.level5_btn:hover {
  background: #0f2329;
  transition: all .25s ease-in-out;
}

.levels-row {
  height: 735px;
}

.white_btn {
  color: #11373e;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #fff;
  border-radius: 5em;
  flex-wrap: nowrap;
  font-weight: 700;
  transition: all .25s ease-in-out;
  position: relative;
}

.white_btn:hover {
  color: #fff;
  background: #c65e0c;
  transition: all .25s ease-in-out;
}

.white_btn_icon {
  transition: all .25s ease-in-out;
  color: #11373e !important;
}

.white_btn:hover > .white_btn_icon {
  transition: all .25s ease-in-out;
  color: #fff !important;
}

.hide {
  display: none !important;
}

.focus\:border-orange:focus {
  --tw-border-opacity: 1;
  border-color: rgb(55 125 153 / var(--tw-border-opacity) );
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

@media (min-width: 640px) {
  .sm\:w-9\/12 {
    width: 75%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-4\/12 {
    width: 33.3333%;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:p-12 {
    padding: 3rem;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .md\:w-9\/12 {
    width: 75%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:ml-2 {
    margin-left: .5rem;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-3\/4, .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-1\/2, .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:place-content-center {
    align-content: center;
    justify-content: center;
  }

  .lg\:place-content-start {
    align-content: start;
    justify-content: start;
  }

  .lg\:place-content-end {
    align-content: end;
    justify-content: end;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pt-14 {
    padding-top: 3.5rem;
  }

  .lg\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1280px) {
  .xl\:w-9\/12 {
    width: 75%;
  }

  .xl\:w-11\/12 {
    width: 91.6667%;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-8\/12 {
    width: 66.6667%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:pr-8 {
    padding-right: 2rem;
  }

  .xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:w-9\/12 {
    width: 75%;
  }

  .\32 xl\:w-11\/12 {
    width: 91.6667%;
  }

  .\32 xl\:w-8\/12 {
    width: 66.6667%;
  }

  .\32 xl\:w-6\/12 {
    width: 50%;
  }

  .\32 xl\:flex-row {
    flex-direction: row;
  }

  .\32 xl\:flex-col {
    flex-direction: column;
  }

  .\32 xl\:pr-8 {
    padding-right: 2rem;
  }

  .\32 xl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

/*# sourceMappingURL=index.b9859e80.css.map */
